


































































































































































import TextField from '@/components/Mobile/TextField.vue';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { TextField },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'getErrorMessage',
    }),
  },
  methods: {
    submitForm() {
      const { email, password } = this.form;
      this.$store.dispatch('auth/signInWithEmail', {
        email,
        password,
      });
    },
  },
});
