




















import Vue from 'vue';
export default Vue.extend({
  name: 'TextField',

  props: {
    label: {
      type: String,
      required: true,
    },
    type: { type: String, required: true },
    placeholder: { type: String, required: true },
    autocomplete: { type: String, required: true },
    value: {
      type: String,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    updateValue(value: string) {
      this.$emit('input', value);
    },
  },
});
